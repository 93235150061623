import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { PUB_STATES } from 'client/constants/pub-states';
import { INSTANT_OFFER } from 'site-modules/shared/constants/appraisal/appraisal';
import { DRAWER_DETAIL_BUTTON_CTA_TEXT } from 'site-modules/shared/components/find-vin-modal/constants';
import { YmmsDropdowns } from 'site-modules/shared/components/ymms-dropdowns/ymms-dropdowns';
import { usePrevious } from 'site-modules/shared/hooks/use-previous';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { FindVinByAddressForm } from './find-vin-by-address-form';

const ERROR_MESSAGES = {
  make: 'Please select a make',
  model: 'Please select a model',
  year: 'Please select a year',
};

const DROPDOWNS_PLACEHOLDERS = {
  year: 'Select year',
  make: 'Select make',
  model: 'Select model',
};

const DROPDOWNS_LABELS = {
  year: 'Year',
  make: 'Make',
  model: 'Model',
};

const DROPDOWNS_PUB_STATES = [PUB_STATES.USED, PUB_STATES.NEW_USED];

export function FindVinByAddressFormViewUI({
  onSubmit: handleSubmit,
  addressStateCode,
  addressZipCode,
  addressCity,
  resetAddressForm,
  setModelValue,
  location,
  zipCode,
  vehicle,
  handleAppraisalOnClick,
  isLandingPage,
  isMobile,
  isOptimizedTraffic,
  ctaBtnColor,
  isSubmitting,
  useUniqId,
  mmy,
  setMmy,
  isAddressTab,
}) {
  const vehicleMakeSlug = get(vehicle, 'make.slug', '');
  const vehicleModelSlug = get(vehicle, 'model.slug', '');
  const vehicleYear = get(vehicle, 'year.year', '');

  const [stateCode, setStateCode] = useState(addressStateCode);
  const [city, setCity] = useState(addressCity);
  const [isMmyIncompleted, setIsMmyIncompleted] = useState(false);
  const { make, model, year } = mmy;
  const { setMmy: setContextMmy } = useAppraisalTabsContext();

  const onChangeMmy = useCallback(
    newMmy => {
      setIsMmyIncompleted(false);
      setMmy(newMmy);
      setContextMmy(newMmy);
    },
    [setContextMmy, setMmy]
  );

  const onSubmit = useCallback(
    (isValid, formValues) => {
      if (isLandingPage && !(make && model && year)) {
        setIsMmyIncompleted(true);
        return;
      }

      if (!isValid) return;

      handleSubmit({ ...formValues, zipCode: addressZipCode, stateCode });
    },
    [addressZipCode, handleSubmit, isLandingPage, make, model, stateCode, year]
  );

  const onStateCodeChange = useCallback(({ stateCode: selectedStateCode }) => setStateCode(selectedStateCode), []);

  useEffect(() => {
    if (resetAddressForm) {
      setStateCode(addressStateCode);
      setCity(addressCity);
      setMmy({});
      setIsMmyIncompleted(false);
    }
  }, [addressCity, addressStateCode, resetAddressForm, setMmy, vehicleMakeSlug, vehicleModelSlug, vehicleYear]);

  const prevZipCode = usePrevious(zipCode);

  useEffect(() => {
    if (zipCode !== prevZipCode) {
      setModelValue('location.address', VisitorModel, location);
    }
  }, [addressZipCode, location, prevZipCode, setModelValue, zipCode]);

  useEffect(() => {
    if (addressStateCode) {
      setStateCode(addressStateCode);
    }
  }, [addressStateCode]);

  useEffect(() => {
    if (addressCity) {
      setCity(addressCity);
    }
  }, [addressCity]);

  useEffect(() => {
    if (vehicleMakeSlug && vehicleModelSlug && vehicleYear && !resetAddressForm) {
      onChangeMmy({
        make: vehicleMakeSlug,
        model: vehicleModelSlug,
        year: vehicleYear,
      });
    }
  }, [onChangeMmy, vehicleMakeSlug, vehicleModelSlug, vehicleYear, resetAddressForm]);

  const makeName = get(vehicle, 'make.name', '');
  const modelName = get(vehicle, 'model.name', '');

  const isAddressNotLanding = isAddressTab && !isLandingPage;

  const renderEscapeHatchCta = (isLink = false) => {
    if (isOptimizedTraffic || isLandingPage) {
      return null;
    }
    return (
      <div className="text-center text-md-start">
        <Button
          color={isLink ? 'link' : 'outline-blue-50'}
          className={classNames('text-transform-none mt-1', {
            'p-0 text-primary-darker': isLink,
            'py-0_5': !isLink,
            'w-100': isMobile,
          })}
          onClick={handleAppraisalOnClick}
          data-index={INSTANT_OFFER}
          data-tracking-id="view_appraisal"
        >
          <span className="fw-normal size-16">
            {isLink
              ? DRAWER_DETAIL_BUTTON_CTA_TEXT.APPRAISE_LINK
              : DRAWER_DETAIL_BUTTON_CTA_TEXT.APPRAISE_ADDRESS_DRAWER}
          </span>
        </Button>
      </div>
    );
  };

  return (
    <Fragment>
      <header
        className={classNames('pos-r text-gray-darker', {
          'mb-1_5': isAddressTab && isLandingPage,
          'mb-1_25': isAddressNotLanding,
          'mt-1_5 mb-1': !isAddressTab,
        })}
      >
        <h4
          className={classNames('text-cool-gray-10', {
            'size-20 fw-bold mt-0_5 mb-0_25': isAddressNotLanding,
            'size-24': !isAddressNotLanding,
          })}
        >
          {isAddressTab && isLandingPage ? `Don’t have your VIN? No Problem!` : `Don’t have your VIN or License Plate?`}
        </h4>
        <div
          className={classNames({
            'size-14 text-black': isAddressNotLanding,
          })}
        >
          We can help you look up your VIN by using your address. This will only be used to look up your VIN, nothing
          else.
        </div>
      </header>
      {isLandingPage && (
        <Row>
          <Col xs={12}>
            <div
              className={classNames(
                'size-14 fw-bold',
                isAddressTab
                  ? 'text-cool-gray-10 category-title bg-blue-100 py-0_5 px-1 mb-1_5'
                  : 'text-uppercase text-gray-darker'
              )}
            >
              Vehicle Information
            </div>
            {!isAddressTab && <hr className="mb-1_25 mt-0 w-100" />}
            <YmmsDropdowns
              useUniqId
              useStyledSelects
              hasModelsDropdown
              hasSubmodelsDropdown={false}
              hasStylesDropdown={false}
              dropdownPlaceholders={DROPDOWNS_PLACEHOLDERS}
              dropdownLabels={DROPDOWNS_LABELS}
              labelClassName="size-12"
              includeNextYear={false}
              formGroupClassName="mb-1"
              onChange={onChangeMmy}
              errorMessages={ERROR_MESSAGES}
              displayError={{
                make: isMmyIncompleted && !make,
                model: isMmyIncompleted && !model,
                year: isMmyIncompleted && !year,
              }}
              pubStates={DROPDOWNS_PUB_STATES}
              isFormControlErrorHighlighted
              dropdownColSize={
                isAddressTab
                  ? {
                      year: {
                        xs: 12,
                        lg: 4,
                      },
                      make: {
                        xs: 12,
                        lg: 4,
                      },
                      model: {
                        xs: 12,
                        lg: 4,
                      },
                    }
                  : undefined
              }
              prepopulatedVehicle={
                make && model && year
                  ? {
                      makeSlug: make,
                      modelSlug: model,
                      year,
                    }
                  : undefined
              }
            />
          </Col>
        </Row>
      )}
      <Row className={classNames({ 'mt-1': isLandingPage, 'mt-1_5': !isLandingPage && !isAddressTab })}>
        <Col xs={12}>
          {isLandingPage && (
            <Fragment>
              <div
                className={classNames(
                  'size-14 fw-bold',
                  isAddressTab
                    ? 'text-cool-gray-10 category-title bg-blue-100 py-0_5 px-1 mb-1_5'
                    : 'text-uppercase text-gray-darker'
                )}
              >
                Address Information
              </div>
              {!isAddressTab && <hr className="mb-1_25 mt-0 w-100" />}
            </Fragment>
          )}
          <FindVinByAddressForm
            isLandingPage={isLandingPage}
            isMobile={isMobile}
            city={city}
            stateCode={stateCode}
            ctaBtnColor={ctaBtnColor}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            useUniqId={useUniqId}
            setStateCode={onStateCodeChange}
            isAddressTab={isAddressTab}
          />
          {!isAddressNotLanding && renderEscapeHatchCta()}
        </Col>
      </Row>
      <div className={classNames('mt-1_5', isAddressTab ? 'size-14 text-cool-gray-50' : 'size-10')}>
        By clicking Look up my VIN, I authorize and direct Edmunds to use my information to look up the Vehicle
        Identification Number (VIN) of the {isLandingPage ? 'vehicle(s) ' : `${makeName} ${modelName} `}
        associated with my address.
      </div>
      {isAddressNotLanding && renderEscapeHatchCta(true)}
    </Fragment>
  );
}

FindVinByAddressFormViewUI.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addressStateCode: PropTypes.string,
  addressZipCode: PropTypes.string,
  addressCity: PropTypes.string,
  resetAddressForm: PropTypes.bool,
  setModelValue: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
  zipCode: PropTypes.string,
  vehicle: PropTypes.shape({}),
  handleAppraisalOnClick: PropTypes.func.isRequired,
  isLandingPage: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOptimizedTraffic: PropTypes.bool,
  ctaBtnColor: PropTypes.string,
  isSubmitting: PropTypes.bool,
  useUniqId: PropTypes.bool,
  setMmy: PropTypes.func.isRequired,
  mmy: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
  }),
  isAddressTab: PropTypes.bool,
};

FindVinByAddressFormViewUI.defaultProps = {
  addressStateCode: '',
  addressZipCode: '',
  addressCity: '',
  resetAddressForm: false,
  location: {},
  zipCode: '',
  vehicle: null,
  isLandingPage: false,
  isMobile: false,
  isOptimizedTraffic: false,
  ctaBtnColor: '',
  isSubmitting: false,
  useUniqId: false,
  mmy: {},
  isAddressTab: false,
};

export const stateToPropsConfig = {
  addressCity: bindToPath('location.address.city', VisitorModel),
  addressStateCode: bindToPath('location.address.stateCode', VisitorModel),
  addressZipCode: bindToPath('location.address.zipCode', VisitorModel),
  zipCode: bindToPath('location.zipCode', VisitorModel),
  location: bindToPath('location', VisitorModel),
};

export const FindVinByAddressFormView = connectToModel(FindVinByAddressFormViewUI, stateToPropsConfig);
