import React from 'react';
import PropTypes from 'prop-types';

export const FindVinByAddressNotFoundViewWithLp = ({ licensePlateAndVinForm, escapeHatchCta }) => (
  <div className="find-vin-by-address-not-found-view-with-lp">
    <div className="text-center">
      <p className="size-24 text-cool-gray-10 fw-bold mb-0_5">
        Unfortunately, we can&apos;t find your VIN at this time.
      </p>
      <p className="text-cool-gray-40 size-14 mb-1_5">
        We can use your License Plate instead. Please provide your License Plate below to get an appraisal.
      </p>
    </div>
    {licensePlateAndVinForm}
    {escapeHatchCta}
  </div>
);

FindVinByAddressNotFoundViewWithLp.propTypes = {
  licensePlateAndVinForm: PropTypes.node,
  escapeHatchCta: PropTypes.node,
};

FindVinByAddressNotFoundViewWithLp.defaultProps = {
  licensePlateAndVinForm: null,
  escapeHatchCta: null,
};
