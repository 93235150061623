import React from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';

import { FindVinByAddressSelectVehicleView } from './find-vin-by-address-select-vehicle-view';
import { FindVinByAddressNotFoundView } from './find-vin-by-address-not-found-view';
import { FindVinByAddressNotFoundViewWithLp } from './find-vin-by-address-not-found-view-with-lp';

export const FindVinByAddressSubmittedView = ({
  vehicles,
  selectedVin,
  setSelectedVin,
  isSubmitting,
  continueWithSelectedVehicle,
  ctaBtnColor,
  isAddressTab,
  licensePlateAndVinForm,
  escapeHatchCta,
}) => {
  if (size(vehicles)) {
    return (
      <FindVinByAddressSelectVehicleView
        vehicles={vehicles}
        selectedVin={selectedVin}
        setSelectedVin={setSelectedVin}
        isSubmitting={isSubmitting}
        continueWithSelectedVehicle={continueWithSelectedVehicle}
        ctaBtnColor={ctaBtnColor}
        isAddressTab={isAddressTab}
      />
    );
  }

  return isAddressTab ? (
    <FindVinByAddressNotFoundViewWithLp
      isAddressTab={isAddressTab}
      licensePlateAndVinForm={licensePlateAndVinForm}
      escapeHatchCta={escapeHatchCta}
    />
  ) : (
    <FindVinByAddressNotFoundView />
  );
};

FindVinByAddressSubmittedView.propTypes = {
  vehicles: PropTypes.arrayOf(PropTypes.shape({})),
  selectedVin: PropTypes.string,
  setSelectedVin: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  continueWithSelectedVehicle: PropTypes.func.isRequired,
  ctaBtnColor: PropTypes.string,
  isAddressTab: PropTypes.bool,
  licensePlateAndVinForm: PropTypes.node,
  escapeHatchCta: PropTypes.node,
};

FindVinByAddressSubmittedView.defaultProps = {
  vehicles: [],
  selectedVin: '',
  isSubmitting: false,
  ctaBtnColor: '',
  isAddressTab: false,
  licensePlateAndVinForm: null,
  escapeHatchCta: null,
};
